<!-- @format -->

<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col xl="10" md="12">
        <b-form @submit.prevent style="background-color: #fff">
          <div class="woc">
            <b-card class="invoice-preview-card">
              <h3 class="text-primary invoice-logo">Add policy</h3>
              <!-- Header -->
              <b-row class="invoice-padding">
                <b-col cols="12" md="6" xs="12" class="mb-lg-1 mb-2">
                  <h5 class="mb-2 text-primary invoice-title">Agent ID</h5>
                  <b-input-group cols="12">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="HashIcon" />
                    </b-input-group-prepend>
                    <b-form-input v-model="formData.create_id" disabled />
                  </b-input-group>
                </b-col>
                <b-col cols="12" md="6" xs="12" class="mb-lg-1 mb-2">
                  <h5 class="mb-2 text-primary invoice-title">Agent Level</h5>
                  <b-input-group cols="12">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="HashIcon" />
                    </b-input-group-prepend>
                    <b-form-input v-model="formData.create_level" disabled />
                  </b-input-group>
                </b-col>
                <b-col cols="12" class="mb-lg-1 mb-2">
                  <h5 class="mb-2 text-primary invoice-title">Agent name</h5>
                  <b-input-group cols="12">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="HashIcon" />
                    </b-input-group-prepend>
                    <b-form-input v-model="formData.create_name" disabled contenteditable />
                  </b-input-group>
                </b-col>
              </b-row>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->
              <b-row class="invoice-spacing mb-0 ml-2">
                <b-col cols="8" class="mb-lg-1">
                  <h6 class="mb-2">Policy Number:</h6>
                  <validation-provider #default="{ errors }" name="Policy Number">
                    <b-form-input v-model="formData.number" placeholder="Policy Number" :state="errors.length > 0 ? false : null" :disabled="yulandis" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="invoice-spacing mb-0 ml-2">
                <b-col cols="8" class="mb-lg-1">
                  <h6 class="mb-2">Select a product:</h6>
                  <validation-provider #default="{ errors }" name="Policy Number">
                    <!--                  <b-form-input v-model="formData.product_id" :state="errors.length > 0 ? false : null" placeholder="Policy Number" />-->
                    <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
                    <b-row style="width: 100%; margin: 0">
                      <b-col cols="12" class="mb-1 p-0">
                        <vSelect placeholder="Select company" v-model="c" :options="comptry" :reduce="item => item.id" label="title" :disabled="yulandis" />
                      </b-col>
                      <b-col cols="12" class="mb-1 p-0">
                        <vSelect placeholder="Select Product Type" v-model="big" :options="bigcoptry" :reduce="item => item.type_id" label="type_name" :disabled="yulandis || !c" />
                      </b-col>
                      <!--                      <b-col>-->
                      <!--                        <vSelect placeholder="Select Product Type" v-model="d" :options="cate" :reduce="item => item.id" label="title" :disabled="yulandis || !c || !big"></vSelect>-->
                      <!--                      </b-col>-->
                      <b-col cols="12" class="mb-1 p-0">
                        <vSelect
                          placeholder="Product"
                          v-model="formData.product_id"
                          :reduce="item => item.id"
                          label="title"
                          :options="productList"
                          :disabled="yulandis || !c || !big"
                          @input="changeCompu"
                        />
                      </b-col>
                    </b-row>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="invoice-spacing mb-0 ml-2">
                <b-col cols="8" class="mb-lg-1">
                  <h6 class="mb-2">Insured:</h6>
                  <validation-provider #default="{ errors }" name="Insured">
                    <b-form-input v-model="formData.protect_user" :state="errors.length > 0 ? false : null" placeholder="Insured" :disabled="yulandis" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="invoice-spacing mb-0 ml-2">
                <b-col cols="8" class="mb-lg-1">
                  <h6 class="mb-2">{{ big == 1 || big == '' ? 'Face Amount' : 'Annuity' }}:</h6>
                  <validation-provider #default="{ errors }" name="FaceAmount">
                    <b-form-input v-model="money" :state="errors.length > 0 ? false : null" placeholder="FaceAmount" :disabled="yulandis || !rate" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="invoice-spacing mb-0 ml-2">
                <b-col cols="8" class="mb-lg-1">
                  <h6 class="mb-2">State:</h6>
                  <validation-provider #default="{ errors }" name="Signed address">
                    <b-form-input v-model="formData.sign_city" :state="errors.length > 0 ? false : null" placeholder="Signed address" :disabled="yulandis" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row class="invoice-spacing mb-0 ml-2">
                <b-col cols="8" class="mb-lg-1">
                  <h6 class="mb-2">Date of creation:</h6>
                  <validation-provider #default="{ errors }" name="Date of creation">
                    <flat-pickr v-model="formData.deal_time" :class="['form-control-time', yulandis ? 'graydis' : '']" :config="timePickerConfig" placeholder="Select creation date" :disabled="yulandis" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <!--              <b-row class="invoice-spacing mb-0 ml-2">-->
              <!--                <b-col cols="8" class="mb-lg-1">-->
              <!--                  <h6 class="mb-2">Policy TP:</h6>-->
              <!--                  <validation-provider #default="{ errors }" name="Policy TP">-->
              <!--                    <b-form-input v-model="formData.order_tp" :state="errors.length > 0 ? false : null" placeholder="Policy TP" :disabled="yulandis || big == 2 || big == ''" />-->
              <!--                    <small class="text-danger">{{ errors[0] }}</small>-->
              <!--                  </validation-provider>-->
              <!--                </b-col>-->
              <!--              </b-row>-->

              <b-card-body class="invoice-padding">
                <div ref="form" class="repeater-form" :style="{ height: trHeight }">
                  <b-row v-for="(item, index) in formData.share_rate" :key="index" ref="row" class="pb-2">
                    <b-col cols="12">
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col cols="12" lg="3"> Agent ID </b-col>
                          <b-col cols="12" lg="3"> Agent name </b-col>
                          <b-col cols="12" lg="3"> Agent Level </b-col>
                          <b-col cols="12" lg="3"> Split Percent </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <b-col cols="12" lg="3">
                            <b-form-input
                              v-model="formData.share_rate[index].search_id"
                              type="number"
                              class="mb-2"
                              @blur="searchUserId(index)"
                              @keyup.enter="searchUserId(index)"
                              placeholder="Agent ID"
                              :disabled="yulandis"
                            />
                          </b-col>
                          <b-col cols="12" lg="3">
                            <b-form-input v-model="formData.share_rate[index].create_name" class="mb-2" disabled placeholder="Agent ID" />
                          </b-col>
                          <b-col cols="12" lg="3">
                            <b-form-input v-model="formData.share_rate[index].create_level" class="mb-2" disabled placeholder="Agent ID" />
                          </b-col>
                          <b-col cols="12" lg="3">
                            <b-form-input v-model="formData.share_rate[index].share_rate" class="mb-2" placeholder="Agent Split Percent" :disabled="!formData.share_rate[index].create_name || yulandis" />
                          </b-col>
                        </b-row>
                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon size="16" icon="XIcon" class="cursor-pointer" @click="removeItem(index)" />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" @click="addNewItemInItemForm"> Add Split Agent </b-button>
              </b-card-body>

              <b-row class="invoice-spacing ml-2">
                <b-col cols="8" class="mb-lg-1">
                  <h6 class="mb-2">Policy Remarks:</h6>
                  <b-form-textarea v-model="formData.desc" placeholder="Policy Remarks" :disabled="yulandis" />   
                </b-col>
              </b-row>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-75" block @click.prevent="submitAddProduct">Confirm</b-button>
            </b-card>
          </div>
        </b-form>
      </b-col>

      <b-col md="12" xl="2" class="invoice-actions mt-md-0 mt-2">
        <b-card style="position: fixed">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-75" block @click.prevent="submitAddProduct">Confirm</b-button>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mb-75" block @click="yulandis = !yulandis">
            {{ yulandis ? 'Cancel Preview' : 'Preview' }}
          </b-button>
          <!--          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mb-75" block @click="downLoad"> Download </b-button>-->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref, getCurrentInstance, computed, nextTick, onMounted, reactive, watch } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ListManagerModule from '@/views/GeneralManagement/ProductManager/List/ListManagerModule'

import { BRow, BCol, BCard, BCardBody, BButton, BForm, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, VBToggle } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axiosIns from '@/libs/axios'
import { useToast } from 'vue-toastification/composition'
import orderModule from '@/views/GeneralManagement/OrderManager/OrderList/OrderManagerList'
// import invoiceStoreModule from '../invoiceStoreModule'
// import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue'
import zh from 'flatpickr/dist/l10n'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import { splice } from 'postcss-rtl/lib/affected-props'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      if (this.formData.share_rate.length < 6) {
        this.$refs.form.style.overflow = 'hidden'
        this.formData.share_rate.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
          setTimeout(() => {
            this.$refs.form.style.overflow = null
          }, 350)
        })
      } else {
        this.$bvToast.toast('Add up to 6 Split Percent', {
          title: 'Tips',
          variant: 'warning',
          solid: true,
          autoHideDelay: 2000,
        })
      }
    },
    removeItem(index) {
      this.formData.share_rate.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      setTimeout(() => {
        this.$nextTick(() => {
          setTimeout(() => {
            this.trSetHeight(this.$refs.form.scrollHeight)
          }, 1000)
        })
      }, 200)
    },
    forceUpdated() {
      this.$forceUpdate()
    },
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const toast = useToast()
    const money = ref(undefined)

    const formData = reactive({
      product_id: '',
      // status: '1',
      number: '',
      deal_time: '',
      order_tp: undefined,
      user_id: '',
      share_rate: [],
      protect_user: '', // 被保人
      sign_city: '', // Signed address
      money: money.value, // FaceAmount
      desc: '',
      create_name: '',
      create_id: '',
      create_level: '',
    })
    // watch: {
    //   big(val) {
    //     if (val == 2) {
    //       this.formData.order_tp = Number(this.rate) * this.formData.money
    //     }
    //   },
    //   'formData.money': {
    //     handler(val) {
    //       console.log(val, this.big, this.rate)
    //       if (this.big == 2) {
    //         this.$set(this.formData, 'order_tp', Number(this.rate) * Number(val))
    //       }
    //     },
    //     deep: true,
    //   },
    // },

    const timePickerConfig = {
      enableTime: true,
      dateFormat: 'Y-m-d H:i:ss',
      // locale: zh.zh, // locale for this instance only
    }
    // const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    // if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    //
    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    // })

    const productList = reactive([])
    const casePagae = ref(1)
    const total = ref(0)
    const hasPrevPage = computed(() => casePagae.value > 1)
    const hasNextPage = computed(() => casePagae.value < Math.ceil(total.value / 20))

    // 会员列表
    const vipList = reactive([])
    const fetchProdList = () => {
      if (!store.hasModule('product-list')) {
        store.registerModule('product-list', ListManagerModule)
      }
      store
        .dispatch('product-list/fetchDataList', {
          pageSize: 20,
          page: casePagae.value,
        })
        .then(response => {
          productList.push(...response.data.list)
          total.value = response.data.total
        })
    }
    const cityList = reactive([])

    const fetchSignCity = () => {
      axiosIns.get('/order/city').then(response => {
        if (response.code === 0) {
          cityList.push(...response.data)
        }
      })
    }

    const itemFormBlankItem = {
      user_id: undefined,
      share_rate: undefined,
    }

    const error = reactive({
      text: '',
      component: '',
    })

    onMounted(() => {
      nextTick(() => {
        // fetchProdList()
        // fetchSignCity()
        axiosIns.get('/user/list').then(response => {
          if (response.code === 0) {
            vipList.push(...response.data.list)
          }
        })
        // 获取默认会员信息
        axiosIns.get('/order/create-user').then(response => {
          formData.create_name = response.data.real_name
          formData.create_id = response.data.id
          formData.create_level = response.data.level.name
        })
        // formData.share_rate.push(JSON.parse(JSON.stringify(itemFormBlankItem)))
      })
    })

    const isLoading = ref(false)

    const INVOICE_APP_STORE_MODULE_NAME = 'user-order'
    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderModule)
    }

    const submitAddProduct = () => {
      isLoading.value = true
      // proxy.$refs.registerForm.validate().then(isOk => {
      //   if (isOk) {
      //     if (error.text || error.component) {
      //       toast.error(error.text)
      //       isLoading.value = false
      //       return
      //    }
      // this.formData.share_rate.forEach(item => {
      //   if (!item.user_id) {
      //     toast.error('请选择分摊人')
      //   }
      //   if (!item.share_rate) {
      //     toast.error('请输入分摊比例')
      //     isLoading.value = false
      //   }
      // })
      store
        .dispatch('user-order/orderCreate', {
          ...formData,
        })
        .then(response => {
          if (response.code == 0) {
            toast.success(response.msg || 'Added successfully')
            setTimeout(() => {
              isLoading.value = false
              proxy.$router.back()
            }, 1000)
          } else {
            isLoading.value = false
            toast.error(response.msg)
          }
        })
        .catch(error => {
          toast.error('Failed to add')
          isLoading.value = false
        })
      //   } else {
      //     toast.error('Please complete the form')
      //     isLoading.value = false
      //   }
      // })
    }

    const downLoad = () => {
      document.getElementById('app').style.backgroundColor = '#fff'
      document.body.style.cursor = 'wait'

      setTimeout(() => {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF()
        html2canvas(document.querySelector('.woc '), {
          height: document.querySelector('.woc').scrollHeight + 300,
          width: document.querySelector('.woc').scrollWidth + 100,
          // height: document.querySelector('.woc').scrollHeight,
          backgroundColor: '#fff',
          scale: 1,
          removeContainer: true,
          allowTaint: true,
          // 背景大小
          bcckgroundSize: 'cover',
        }).then(canvas => {
          const imgData = canvas.toDataURL('image/jpeg')
          doc.addImage(imgData, 'JPEG', 0, 0, 320, 320, '')
          doc.save('invoice.pdf')
          setTimeout(() => {
            document.body.style.cursor = 'default'
          }, 1000)
        })
      }, 1000)
    }

    const searchId = ref(null)
    const searchUserId = val => {
      if (!formData.share_rate[val].search_id) {
        formData.share_rate[val] = {}
        proxy.forceUpdated()
        return
      }
      axiosIns.get(`/user/getUserInfoById?id=${formData.share_rate[val].search_id}`).then(res => {
        if (res.code == 0) {
          // 搜索原有数据,查找是否存在该树
          const findIndex = formData.share_rate.findIndex(item => item.user_id == res.data.id)
          if (findIndex < 0) {
            nextTick(() => {
              formData.share_rate[val].user_id = res.data.id
              formData.share_rate[val].share_rate = res.data.share_rate
              formData.share_rate[val].create_name = res.data.real_name
              formData.share_rate[val].create_level = res.data.level.name
            })
            proxy.forceUpdated()
          } else if (findIndex == 0) {
            toast('Agent Already exists!')
            formData.share_rate[val] = {}
            proxy.forceUpdated()
          }
        } else {
          toast(res.msg, {
            type: 'error',
            duration: 2000,
          })
          formData.share_rate[val] = {}
          proxy.forceUpdated()
        }
      })
    }
    const rate = ref(0)

    const comptry = reactive([])
    const cate = reactive([])
    const bigcoptry = reactive([])
    const c = ref('')
    const d = ref('')
    const big = ref('')

    onMounted(() => {
      axiosIns.get('/pro-brand/getBrandList').then(res => {
        if (res.code === 0) {
          comptry.push(...res.data)
        }
      })
      axiosIns.get('/pro-cate/getCateList').then(res => {
        if (res.code === 0) {
          cate.push(...res.data)
        }
      })
      axiosIns.get('/pro-cate/getProductType').then(res => {
        // console.log(res.data)
        if (res.code === 0) {
          bigcoptry.push(...res.data)
        }
      })
    })
    watch(
      [c, big, money],
      () => {
        // if (big.value == 2) {
        //   formData.order_tp = Number(rate.value) * Number(money.value) || undefined
        // } else {
        //   formData.order_tp = undefined
        // }
        formData.money = money.value
        if (c.value) {
          axiosIns.get(`/product/getProduct?brand_id=${c.value}&cate_id=${big.value || 1}`).then(res => {
            if (res.code === 0) {
              productList.splice(0, productList.length, ...res.data)
            }
          })
        }
      },
      { immediate: true },
    )

    const yulandis = ref(false)
    const changeCompu = val => {
      formData.product_id = val
      rate.value = productList.find(item => item.id == val).rate
    }

    return {
      changeCompu,
      rate,
      yulandis,
      c,
      d,
      big,
      comptry,
      bigcoptry,
      cate,
      itemFormBlankItem,
      hasNextPage,
      hasPrevPage,
      formData,
      submitAddProduct,
      isLoading,
      timePickerConfig,
      searchId,
      searchUserId,
      productList,
      downLoad,
      money,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
.woc {
  //overflow: hidden;
  background-color: transparent;

  >>> div.card {
    background-color: #ffffff !important;
  }
}
.graydis {
  background-color: #efefef !important;
}
.form-control-time {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  display: block;
  width: 100%;
  height: 2.714rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.diy-input {
  max-width: max-content;
  .input-group-merge {
    max-width: max-content !important;
    input.form-control {
      min-width: 412px !important;
    }
  }
}
</style>
